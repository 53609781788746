import { createContext, useContext, useState } from "react";
const MyContext = createContext();

export const MyContextProvider = ({ children }) => {
  const [themeLogo, setThemeLogo] = useState("");
  const [allUploadedFiles, setAllUploadedFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedNewFiles, setUploadedNewFiles] = useState([]);
  return (
    <MyContext.Provider
      value={{
        themeLogo,
        setThemeLogo,
        allUploadedFiles,
        setAllUploadedFiles,
        selectedFiles,
        setSelectedFiles,
        uploadedNewFiles,
        setUploadedNewFiles,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};
export const useMainContext = () => {
  return useContext(MyContext);
};

export default MyContext;
