import { HelmetProvider } from "react-helmet-async";
import React, { lazy } from "react";
import { MyContextProvider } from "./context/MainContext";
import { UIConfigProvider } from "./context/UIConfigContext";
const MyBeShowNavbar = lazy(() =>
  import("./component/MyBeShowNavbar/MyBeShowNavbar")
);
const Dashboard = lazy(() => import("./component/Dashboard/Dashboard"));
const OrderNotification = lazy(() =>
  import("./component/OrderNotification/OrderNotification")
);

function App() {
  return (
    <MyContextProvider>
      <UIConfigProvider>
        <HelmetProvider>
          <MyBeShowNavbar>
            <OrderNotification />
            <Dashboard />
          </MyBeShowNavbar>
        </HelmetProvider>
      </UIConfigProvider>
    </MyContextProvider>
  );
}

export default App;
