import { createContext, useContext, useState } from "react";
import {
  API_AUTH_PASSWORD,
  API_AUTH_USERNAME,
  API_BASE_URL,
} from "../Constant/Global";
import axios from "axios";

const UIConfigContext = createContext();

export const UIConfigProvider = ({ children }) => {
  const [uiConfig, setUIConfig] = useState({});
  const [loading, setLoading] = useState(false);

  const getUIConfig = async (pageName) => {
    if (!uiConfig[pageName]) {
      setLoading(true);
      try {
        const response = await axios.get(
          API_BASE_URL + `admin/admin-ui-configurations/page/${pageName}`,
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          }
        );
        if (response.data.status) {
          const configData = response.data.data[0];
          const parsedConfig = JSON.parse(configData.other_configration);
          setUIConfig(parsedConfig);
        }
      } catch (error) {
        console.error("Failed to fetch UI config:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <UIConfigContext.Provider value={{ uiConfig, getUIConfig, loading }}>
      {children}
    </UIConfigContext.Provider>
  );
};

export const useUIConfig = () => {
  return useContext(UIConfigContext);
};
